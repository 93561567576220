import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Toolbar,
  AppBar,
  Menu,
  MenuItem,
  FormControl,
  Select,
 
} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-scroll";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleTheme,
  getTheme,
  setLang,
  getLang,
} from "../redux/actions/uiAction";

import * as tl from "../lang/tl";
import * as en from "../lang/en";
import * as zh from "../lang/zh";
import * as ar from "../lang/ar";



const classes = {
  root: {
    mx: {
      xs: 5,
      sm: 10,
      md: 15,
      lg: 20,
      xl: 25,
    },
  },

  appBar: {
    boxShadow: 0,
  },
  toolbar: {
    padding: 5,

    backgroundColor: (theme) => theme.palette.background.default,
  },

  logoText: {
    fontSize: 28,
    fontFamily: " Arial",
    mx: 0.1,
  },
  logoText1: {
    fontSize: 25,
    fontWeight: 800,

    fontFamily: " Arial",
  },
  menuCointainer: {
    alignItems: "center",
    display: {
      xs: "none",
      sm: "none",
      md: "flex",
    },
  },
  menuLink: {
    "&:hover": {
      color: (theme) => theme.palette.secondary.main,
      fontSize:15
    },

    marginRight: {
      md: 2,
      lg: 4,
    },
    fontSize: {
      md: 10,
      lg: 14,
    },
    borderRadius: 5,
    textTransform: "capitalize",
  },
  menuLink1: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  section1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    backgroundColor: (theme) => theme.palette.background.default,
  },

  buttonStyle1: {
    width: 250,
    borderRadius: 25,
    padding: 2,
    marginTop: 5,
  },

  button: {
    borderRadius: 2,
    ml: 5,
    m: 2,
    "&:hover": {
      backgroundColor: "transparent",
    },
    textTransform: "none",
  },
  buttonLabel: {
    fontSize: 12,
    "&:hover": {
      color: (theme) => theme.palette.secondary.main,
      
    },
  },
  menu: {
    height: 40,
    color: (theme) => theme.palette.common.white,
    display: {
      sm: "flex",
      md: "none",
      xl: "none",
    },
    alignItems: "right",
  },
  icon: {
    color: "textPrimary",
  },
};


export default function Header() {


 
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //darkmode
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);

  useEffect(() => {
    dispatch(getTheme());
   

  }, [dispatch]);

  useEffect(() => {
    dispatch(getLang());
  }, [dispatch]);

  const _toggleTheme = () => {
    dispatch(toggleTheme(!ui.isDarkMode));
  };



  return (
    <Box sx={classes.root}>
      <AppBar position="static" sx={classes.appBar}>
        <Toolbar sx={classes.toolbar}>
          <Typography variant="h7" sx={classes.logoText1}>
          乃ﾉｲ
          </Typography>
          {/* <Typography variant="h7" sx={classes.logoText}>
            i
          </Typography>
          <Typography variant="h7" sx={classes.logoText}>
            t
          </Typography> */}
          <Box component="span" sx={{ flexGrow: 0.5 }} />
          
          <Box sx={classes.menuCointainer}>
            <Button sx={classes.menuLink1}>
              <Link  to="about" spy={true} smooth={true} sx={classes.menuLink1}>
                <Typography color="textPrimary" sx={classes.menuLink}>
                  {ui.lang === "en" ? en.HEADER_ABOUT  : ui.lang === "zh" ? zh.HEADER_ABOUT :  ui.lang === "ar" ? ar.HEADER_ABOUT :  tl.HEADER_ABOUT}
                </Typography>
              </Link>
            </Button>
            <Button sx={classes.menuLink1}>
              <Link to="work" spy={true} smooth={true}>
                <Typography color="textPrimary" sx={classes.menuLink}>
                  {ui.lang === "en" ? en.HEADER_WORKS : ui.lang === "zh" ? zh.HEADER_WORKS : ui.lang === "ar" ? ar.HEADER_WORKS : tl.HEADER_WORKS}
                </Typography>
              </Link>
            </Button>
            <Button sx={classes.menuLink1}>
              <Link
                to="testimonials"
                spy={true}
                smooth={true}
                sx={classes.menuLink1}
              >
                <Typography color="textPrimary" sx={classes.menuLink}>
                  {ui.lang === "en" ? en.HEADER_TESTIMONIALS  : ui.lang === "zh" ? zh.HEADER_TESTIMONIALS : ui.lang === "ar" ? ar.HEADER_TESTIMONIALS : tl.HEADER_TESTIMONIALS}
                </Typography>
              </Link>
            </Button>
            {/**  <Link href="#" sx={classes.menuLink} underline="none">
              Testimonials
            </Link>
             */}
          </Box>
          <Box component="span" sx={{ flexGrow: 0.5 }} />
          <Box sx={classes.menuCointainer}>
            <Button
              size="small"
              sx={classes.button}
              startIcon={
                <Typography color="textPrimary" sx={{ mt: 1 }}>
                  <GitHubIcon sx={{ fontSize: 20 }} />
                </Typography>
              }
              onClick={() =>
                (window.location.href = "https://github.com/vincedaniel08")
              }
            >
              <Typography sx={classes.buttonLabel} color="textPrimary">
                Github
              </Typography>
            </Button>
            <Button
              size="small"
              variant="text"
              sx={classes.button}
              startIcon={
                <Typography color="textPrimary" sx={{ mt: 1 }}>
                  <YouTubeIcon sx={{ fontSize: 20 }} />
                </Typography>
              }
              onClick={() =>
                (window.location.href = "https://youtube.com/bitvideos")
              }
            >
              <Typography sx={classes.buttonLabel} color="textPrimary">
                Youtube
              </Typography>
            </Button>

            <FormControl variant="standard"  sx={{ mb: 3, ml: 2,mt:3 }}>
            
              <Select
                value={ui.lang}
                label="Language"
                onChange={event => dispatch(setLang(event.target.value)) }
                sx={{ fontSize: 10 }}
                disableUnderline
              >
                <MenuItem value={"en"} sx={{ fontSize: 10 }}>
                  English
                </MenuItem>
                <MenuItem value={"tl"} sx={{ fontSize: 10 }}>
                  Tagalog
                </MenuItem>
                <MenuItem value={"zh"} sx={{ fontSize: 10 }}>
                  Chinese
                </MenuItem>
                <MenuItem value={"ar"} sx={{ fontSize: 10 }}>
                  Arabic
                </MenuItem>
              </Select>
            </FormControl>

            <Button
              size="small"
              variant="text"
              sx={classes.button}
              onClick={_toggleTheme}
            >
              <Typography sx={classes.buttonLabel} color="textPrimary">
                {ui.isDarkMode ? <DarkModeIcon /> : <LightModeIcon />}
              </Typography>
            </Button>
          </Box>
          <Box sx={classes.menu}>
            <IconButton
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MenuIcon
                fill="white"
                style={{ height: "inherit", fontSize: 30 }}
                color="textPrimary"
              />
            </IconButton>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>
                {" "}
                <Link to="about" spy={true} smooth={true} onClick={handleClose}>
                  {" "}
                  About
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                {" "}
                <Link to="work" spy={true} smooth={true} onClick={handleClose}>
                  {" "}
                  Works
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                {" "}
                <Link
                  to="testimonials"
                  spy={true}
                  smooth={true}
                  onClick={handleClose}
                >
                  {" "}
                  Testimonials
                </Link>
              </MenuItem>

              <MenuItem onClick={handleClose}>
                <Link size="small" onClick={_toggleTheme}>
                  <Typography color="textPrimary">
                    {ui.isDarkMode ? "Dark Mode" : "Light Mode"}
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem >
                <FormControl variant="standard" fullWidth sx={{ backgroundColor: "transparent",}}>
                  
                  <Select
                    value={ui.lang}
                    label="Language"
                    onChange={event => dispatch(setLang(event.target.value)) }
                    sx={{ fontSize: 12,backgroundColor: "transparent" }}
                    disableUnderline
                  >
                    <MenuItem value={"en"} sx={{ fontSize: 12}}>
                      English
                    </MenuItem>
                    <MenuItem value={"tl"} sx={{ fontSize: 12}}>
                      Tagalog
                    </MenuItem>
                    <MenuItem value={"zh"} sx={{ fontSize: 12 }}>
                    Chinese
                </MenuItem>
                <MenuItem value={"ar"} sx={{ fontSize: 12 }}>
                    Arabic
                </MenuItem>
                  </Select>
                </FormControl>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
