import React from "react";
import { Box } from "@mui/system";
import bitPic from "./images/bit2.png";
import { Typography } from "@mui/material";


import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import CodeOffIcon from "@mui/icons-material/CodeOff";

//
import { useSelector } from "react-redux";
import * as tl from "../lang/tl";
import * as en from "../lang/en";
import * as zh from "../lang/zh";
import * as ar from "../lang/ar";

const classes = {
  mainBox: {
    display: {
      xs: "block",
      sm: "block",
      md: "flex",
      xl: "flex",
    },
    mt: {
      xs: 5,
      sm: 7,
      md: 8,
      xl: 8,
    },
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "center",

    mx: {
      xs: 5,
      sm: 10,
      md: 15,
      lg: 21,
      xl: 22,
    },
  },
  oneBox: {
    display: {
      xs: "block",
      sm: "block",
      md: "flex",
      xl: "flex",
    },
    flexDirection: "column",
    alignItems: "center",
    textAlign: {
      xs: "center",
      sm: "center",
      md: "left",
      xl: "left",
    },
    mx: {
      xs: 1,
      sm: 5,
      md: 8,
      lg: 6,
    },
  },
  pictureBox: {
    width: {
      md: 800,
      xl: 1200,
    },
    height: {
      md: 420,
      xl: 500,
    },
  },
  typography: {
    fontSize: {
      xs: 20,
      sm: 25,
      md: 35,
      xl: 46,
    },
  },
  iconTypography: {
    fontSize: {
      xs: 15,
      sm: 20,
      md: 25,
      xl: 30,
    },
    color: "#5DDC9A",
    border: 1,
    borderColor: "transparent",
    "&:hover": {
      color: "black",
    },
  },
  oneTypography: {
    fontSize: {
      xs: 12,
      sm: 15,
      md: 15,
      xl: 20,
    },
    color: "gray",
    mt: 2,
  },
  twoTypography: {
    fontSize: {
      xs: 15,
      sm: 18,
      md: 20,
      xl: 30,
    },

    mt: {
      xs: 7,
      sm: 8,
      md: 10,
      xl: 12,
    },
    mb: 5,
  },
  threeTypography: {
    fontSize: {
      xs: 12,
      sm: 18,
      md: 12,
      xl: 17,
    },

    ml: 2,
    color: "gray",
  },
  boxTypography: {
    display: {
      xs: "flex",
      sm: "flex",
      md: 12,
      xl: 17,
    },

    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    mx: 1,
  },
  boxTypographyOne: {
    mx: 1,
  },

  statusBox: {
    p: 0.7,
    mb: 2,
    backgroundColor: "#5DDC9A",

    fontWeight: 500,
    borderRadius: 1,
    fontSize: 12,
    mr: {
      md: 0,
      lg: 30,
    },
    mx: {
      xs: 12,
      sm: 15,
      md: 0,
    },
  },
};
export default function AboutMe() {
  const ui = useSelector((state) => state.ui);
  return (
    <Box sx={classes.mainBox}>
     

      <Box sx={classes.pictureBox}>
   
      
        <img src={bitPic} alt="gagag" width="100%" height="100%" />
      
      </Box>
    

      <Box sx={classes.oneBox}>
        <Typography sx={classes.typography} color="textPrimary">
          <ArrowLeftOutlinedIcon sx={classes.iconTypography} />{" "}
          {ui.lang === "en" ? en.ABOUT_FRONT_END : ui.lang === "zh" ? zh.ABOUT_FRONT_END :  ui.lang === "ar" ? ar.ABOUT_FRONT_END : tl.ABOUT_FRONT_END}{" "}
          <SettingsOutlinedIcon sx={classes.iconTypography} />{" "}
          {ui.lang === "en" ? en.ABOUT_DESIGNER : ui.lang === "zh" ? zh.ABOUT_DESIGNER : ui.lang === "ar" ? ar.ABOUT_DESIGNER : tl.ABOUT_DESIGNER}{" "}
          <CodeOffIcon sx={classes.iconTypography} />{" "}
          {ui.lang === "en" ? en.ABOUT_WEB_DEVELOPER : ui.lang === "zh" ? zh.ABOUT_WEB_DEVELOPER : ui.lang === "ar" ? ar.ABOUT_WEB_DEVELOPER : tl.ABOUT_WEB_DEVELOPER}{" "}
          <ArrowRightOutlinedIcon sx={classes.iconTypography} />
        </Typography>

        <Typography sx={classes.oneTypography}>
          {" "}
          {ui.lang === "en" ? en.ABOUT_QUOTE : ui.lang === "zh" ? zh.ABOUT_QUOTE : ui.lang === "ar" ? ar.ABOUT_QUOTE : tl.ABOUT_QUOTE}
        </Typography>
        <Box sx={classes.boxTypography}>
          <Box sx={classes.boxTypographyOne}>
            <Typography sx={classes.twoTypography} color="textPrimary">
            {ui.lang === "en" ? en.ABOUT_STUDENT : ui.lang === "zh" ? zh.ABOUT_STUDENT : ui.lang === "ar" ? ar.ABOUT_STUDENT : tl.ABOUT_STUDENT}
              <Typography sx={classes.threeTypography}>
                Bulacan State University
              </Typography>
            </Typography>
          </Box>

          <Box sx={classes.boxTypographyOne}>
            <Typography sx={classes.twoTypography} color="textPrimary">
              3
              <Typography sx={classes.threeTypography}>
              {ui.lang === "en" ? en.ABOUT_PROJECT_COMPLETE : ui.lang === "zh" ? zh.ABOUT_PROJECT_COMPLETE : ui.lang === "ar" ? ar.ABOUT_PROJECT_COMPLETE : tl.ABOUT_PROJECT_COMPLETE}
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
