import React from "react";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { Typography, Tab, Tabs, Rating, Avatar } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { styled } from "@mui/material/styles";
import bitPic from "./images/bitOrig.jpg";

import { useSelector } from "react-redux";
import * as tl from "../lang/tl";
import * as en from "../lang/en";
import * as zh from "../lang/zh";
import * as ar from "../lang/ar";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#5DDC9A",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

const classes = {
  mainBox: {
    display: {
      xs: "block",
      sm: "block",
      md: "flex",
      xl: "flex",
    },
    mt: 10,
    flexDirection: "row",
    alignItems: "center",
    textAlign: "left",
    backgroundColor: (theme) => theme.palette.background.default,
    mx: 5,
    mb: 5,
  },
  oneBox: {
    mb: 5,
    width: "auto",
    mx: {
      xs: 1,
      sm: 5,
      md: 20,
      xl: 30,
    },
    boxShadow: 10,
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    borderTop:1,
    borderLeft:1,
    borderColor:"transparent",
    '&:hover': {
      borderColor: theme => theme.palette.primary.main},
  },

  twoBox: {
    width: "auto",
    flexGrow: 1,
    mx: {
      xs: 1,
      sm: 5,
      md: 10,
      xl: 10,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    mb: -0.2,
  },
  threeBox: {
    mt: 10,
    mb: 5,
    width: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: (theme) => theme.palette.background.default,
    my: 10,
    border: 1,
    borderColor: (theme) => theme.palette.primary.main,
    borderRadius: 3,
    m: {
      xs: 5,
      sm: 8,
      md: 5,
      xl: 0,
    },
  },

  fourBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    p: 2,
    mt: 2,
  },
  fiveBox: {
    mt: 1,
    p: 2,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: {
      xs: 15,
      sm: 22,
      md: 23,
      xl: 25,
    },
    mb: 5,
  },

  titleTypography: {
    ml: {
      xs: 0,
      md: 0,
      lg: 5,
    },
    fontSize: 35,
    mb: 3,
  },
  bodyTypography: {
    ml: {
      xs: 0,
      md: 8,
      lg: 12,
    },

    fontSize: 12,
  },
  startIcon: {
    opacity: 0.55,
    fontSize: {
      xs: 15,
      sm: 17,
      md: 19,
      xl: 20,
    },
  },
  quoteIcon: {
    fontSize: {
      xs: 40,
      sm: 60,
      md: 70,
      xl: 100,
    },
    color: "gray",
    mt: {
      xs: -15,
      sm: -25,
      md: -20,
      xl: -15,
    },
  },
  boxXs: {
    display: {
      xs: "flex",
      sm: "flex",
      md: "none",
      xl: "none",
    },
    width: "100%",
  },
  boxTab: {
    width: {
      xs: "70%",
      sm: "70%",
      md: "70%",
      lg: "70%",
      xl: "75%",
    },
    borderBottom: 1,
    borderColor: "divider",
    color: "gray",
    mt: 5,
  },
  title: {
    color: "primaryText",
    display: {
      xs: "block",
      sm: "block",
      md: "flex",
      lg: "flex",
      xl: "flex",
    },
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    m: 3,
  },
  tabText: {
    color: "primaryText",
  },
};

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
 
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function Testimonials() {
  //tabs
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const startValue = 3.5;
  const endValue = 5;
  
  const ui = useSelector((state) => state.ui);
  return (
    <Box sx={classes.mainBox}>
      <Box sx={classes.boxXs} />
      <Box sx={classes.oneBox}>
        <Box sx={classes.title}>
          <Typography sx={classes.titleTypography} color="textPrimary">
          {ui.lang === "en" ? en.TESTIMONIALS : ui.lang === "zh" ? zh.TESTIMONIALS : ui.lang === "ar" ? ar.TESTIMONIALS : tl.TESTIMONIALS}
          </Typography>
          <Typography sx={classes.bodyTypography} color="textPrimary">
          {ui.lang === "en" ? en.TESTIMONIALS_CLIENT : ui.lang === "zh" ? zh.TESTIMONIALS_CLIENT : ui.lang === "ar" ? ar.TESTIMONIALS_CLIENT : tl.TESTIMONIALS_CLIENT}
          </Typography>
        </Box>
        <Box sx={classes.twoBox}>
          <Box sx={classes.threeBox}>
            <TabPanel value={value} index={0}>
              <Box sx={classes.fourBox}>
                <StyledRating
                  name="text-feedback"
                  value={endValue}
                  readOnly
                  precision={0.5}
                  icon={<StarIcon sx={classes.startIcon} />}
                  emptyIcon={<StarIcon sx={classes.startIcon} />}
                />
                <Typography sx={{ ml: 2 }} color="textPrimary">
                  {labels[endValue]}
                </Typography>
              </Box>

              <Typography sx={classes.fiveBox} color="textPrimary">
                Vince was a real pleasure to work with and we look forward to
                working with him again
                <FormatQuoteIcon sx={classes.quoteIcon} />
              </Typography>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <Box sx={classes.fourBox}>
                <StyledRating
                  name="text-feedback"
                  value={startValue}
                  readOnly
                  precision={0.5}
                  icon={<StarIcon sx={classes.startIcon} />}
                  emptyIcon={<StarIcon sx={classes.startIcon} />}
                />
                <Typography sx={{ ml: 2 }} color="textPrimary">
                  {labels[startValue]}
                </Typography>
              </Box>

              <Typography sx={classes.fiveBox} color="textPrimary">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Faucibus nisl tincidunt eget nullam non nisi.
                <FormatQuoteIcon sx={classes.quoteIcon} />
              </Typography>
            </TabPanel>

            <TabPanel value={value} index={2}>
              <Box sx={classes.fourBox}>
                <StyledRating
                  name="text-feedback"
                  value={startValue}
                  readOnly
                  precision={0.5}
                  icon={<StarIcon sx={classes.startIcon} />}
                  emptyIcon={<StarIcon sx={classes.startIcon} />}
                />
                <Typography sx={{ ml: 2 }} color="textPrimary">
                  {labels[startValue]}
                </Typography>
              </Box>

              <Typography sx={classes.fiveBox} color="textPrimary">
                Vince was a real pleasure to work with and we look forward to
                working with him again
                <FormatQuoteIcon sx={classes.quoteIcon} />
              </Typography>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Box sx={classes.fourBox}>
                <StyledRating
                  name="text-feedback"
                  value={endValue}
                  readOnly
                  precision={0.5}
                  icon={<StarIcon sx={classes.startIcon} />}
                  emptyIcon={<StarIcon sx={classes.startIcon} />}
                />
                <Typography sx={{ ml: 2 }} color="textPrimary">
                  {labels[endValue]}
                </Typography>
              </Box>

              <Typography sx={classes.fiveBox} color="textPrimary">
                Posuere morbi leo urna molestie at elementum eu facilisis sed.
                Est ullamcorper eget nulla facilisi etiam dignissim diam quis
                enim. Mattis rhoncus urna neque viverra justo nec ultrices.
                Rhoncus aenean vel elit scelerisque mauris pellentesque
                pulvinar.
                <FormatQuoteIcon sx={classes.quoteIcon} />
              </Typography>
            </TabPanel>
          </Box>

          <Box sx={classes.boxTab}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              
              
            >
              <Tab
                sx={classes.tabText}
                label="Nico Mapoy"
                {...a11yProps(0)}
                icon={<Avatar alt="Remy Sharp" src={bitPic} />}
                iconPosition="start"
                wrapped
              />
              <Tab
                sx={classes.tabText}
                label="Nico Mapoy"
                {...a11yProps(1)}
                icon={<Avatar alt="Remy Sharp" src={bitPic} />}
                iconPosition="start"
                wrapped
              />
              <Tab
                sx={classes.tabText}
                label="Nico Mapoy"
                {...a11yProps(2)}
                icon={<Avatar alt="Remy Sharp" src={bitPic} />}
                iconPosition="start"
                wrapped
              />
              <Tab
                sx={classes.tabText}
                label="Nico Mapoy"
                {...a11yProps(3)}
                icon={<Avatar alt="Remy Sharp" src={bitPic} />}
                iconPosition="start"
                wrapped
              />
            </Tabs>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
