import React from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { MessengerChat } from "react-messenger-chat-plugin";
import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import Header from "../components/Header";
import AboutMe from "../components/AboutMe";
import Introduce from "../components/Introduce";
import LatestWorks from "../components/LatestWorks";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const classes = {
  root: {
    backgroundColor: (theme) => theme.palette.background.default,
  },
};

export default function Index() {
  return (
    <Box sx={classes.root}>
      <Helmet>
        <title>Vince Daniel</title>
        <meta
          name="Vince Daniel Portfolio"
          content="FRONT END, DESIGNER WEB, WEB AND MOBILE DEVELOPER "
        />
      </Helmet>
      <div data-aos="fade-down" id="home">
        <Header />
      </div>
      <div data-aos="fade-right">
        <AboutMe />
      </div>
      <div data-aos="fade-up" data-aos-anchor-placement="top-center" id="about">
        <Introduce />
      </div>
      <div id="work">
        <LatestWorks />
      </div>
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
        id="testimonials"
      >
        <Testimonials />
      </div>
      <MessengerCustomerChat
        pageId="105219132153873"
        appId="1382472638933427"
      />
      <MessengerChat
        pageId="105219132153873"
        language="en_US"
        themeColor={"#5DDC9A"}
        height={24}
        loggedInGreeting="Hello logged in user!"
        loggedOutGreeting="Hello stranger!"
        autoExpand={true}
        debugMode={false}
        onMessengerShow={() => {
          console.log("onMessengerShow");
        }}
        onMessengerHide={() => {
          console.log("onMessengerHide");
        }}
        onMessengerDialogShow={() => {
          console.log("onMessengerDialogShow");
        }}
        onMessengerDialogHide={() => {
          console.log("onMessengerDialogHide");
        }}
      />
      ;<div id="fb-root"></div>
      <div id="fb-customer-chat" class="fb-customerchat"></div>
      <script>
        var chatbox = document.getElementById('fb-customer-chat');
        chatbox.setAttribute("page_id", "105219132153873");
        chatbox.setAttribute("attribution", "biz_inbox");
      </script>
      <Footer />
    </Box>
  );
}
