import React from "react";
import { Box } from "@mui/system";
import { Typography, Paper, Grid } from "@mui/material";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import BuildIcon from '@mui/icons-material/Build';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import { useSelector } from "react-redux";

import * as tl from "../lang/tl";
import * as en from "../lang/en";
import * as zh from "../lang/zh";
import * as ar from "../lang/ar";

const classes = {
  mainBox: {
    display: {
      xs: "block",
      sm: "block",
      md: "flex",
      xl: "flex",
    },
    flexDirection: "row",
    alignItems: "center",
    textAlign: "left",
    backgroundColor: (theme) => theme.palette.primary.main,
   boxShadow:10,
  },
  oneBox: {
    mt: {
      xs: 8,
      sm: 8,
      md:0,
      lg: 15,
    },
    mb: 10,
    width: "auto",
    mx: {
      xs: 1,
      sm: 5,
      md: 5,
      xl: 12,
    },
  
  },

  onePaper: {
    p: 2,
    margin: "auto",
    maxWidth: "auto",
    flexGrow: 1,
    backgroundColor: (theme) => theme.palette.background.default,
    mx: {
      xs: 2,
      sm: 10,
      md: 12,
      xl: 15,
    },
    mt: 2,
    display:{
      xs: "flex",
      sm: "flex",
    
    },
    border:1,
    borderColor: "transparent",
    '&:hover': {
      borderColor: "black"},
  },

  titleTypography: {
    color: "textPrimary",
    m: 1,
    fontSize: {
      xs: 15,
      sm: 17,
      md: 20,
      xl: 25,
    },
  },
  bodyTypography: {
    color: "textPrimary",
    fontSize: {
      xs: 10,
      sm: 12,
      md: 13,
      xl: 15,
    },
    ml:1,
  },
  buttonTitle: {
    
    cursor: "pointer",
    color: "textPrimary",
    mt: 4,
    mb: 1.5,
    fontSize: {
      xs: 14,
      sm: 15,
      md: 16,
      lg: 17,
      xl: 18,
    },
  },
  icon: {
    color: "#5DDC9A",
    ml: 2,
    mr: 5,
    mt: 2,
    
  },

  twoBox: {
    mt: 12,
    width: {
      xs: "80%",
      sm: "80%",
      md: "40%",
      lg: "55%",
    },
    mb: 10,
    textAlign: {
      xs: "center",
      sm: "center",
      md: "left",
      xl: "left",
    },
    mx: {
      xs: 10,
      sm: 15,
      md: 0,
      xl: 0,
    },
  },
  introduce: {
    mr: 10,
    fontSize: 14,
    color: "white",
  },
  name: {
    fontSize: 35,
    mt: 2,
    mr: 10,
    color: "white",
  },
  quotes: {
    fontSize: 25,
    fontStyle: "italic",
    fontWeight: 100,
    mt: 4,
    mr: 10,
    color: "white",
  },
  about: {
    color: "white",
    mt: 5,
    mr: 10,
  },

  boxXs: {
    display: {
      xs: "flex",
      sm: "flex",
      md: "none",
      xl: "none",
    },
    width: "100%",
  },
};
export default function Introduce() {
  const ui = useSelector((state) => state.ui);
  return (
    <Box sx={classes.mainBox}>
      <Box sx={classes.boxXs} />
      <Box sx={classes.oneBox}>

        <Paper sx={classes.onePaper} >
          <Grid container spacing={4}>
            <Grid item></Grid>
            <Grid item xs={115} sm container>
              <Grid item xs container direction="column" spacing={5}>
                <Grid item xs>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    component="div"
                    sx={classes.titleTypography}
                  >
                   {ui.lang === "en" ? en.INTRODUCE_EDITING_DESIGN  : ui.lang === "zh" ? zh.INTRODUCE_EDITING_DESIGN  : ui.lang === "ar" ? ar.INTRODUCE_EDITING_DESIGN  : tl.INTRODUCE_EDITING_DESIGN }{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={classes.bodyTypography}
                  >
                  Figma , Adobe Photoshop , Bannersnack , Filmora , Adobe Premiere Pro 
                  </Typography>

                  <Grid item>
                    <Typography variant="body2" sx={classes.buttonTitle}>
                      
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <DesignServicesIcon sx={classes.icon} />
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Paper sx={classes.onePaper}>
          <Grid container spacing={4}>
            <Grid item></Grid>
            <Grid item xs={115} sm container>
              <Grid item xs container direction="column" spacing={5}>
                <Grid item xs>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    component="div"
                    sx={classes.titleTypography}
                  >
                      {ui.lang === "en" ? en.INTRODUCE_DEVELOPER : ui.lang === "zh" ? zh.INTRODUCE_DEVELOPER  : ui.lang === "ar" ? ar.INTRODUCE_DEVELOPER  : tl.INTRODUCE_DEVELOPER }{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={classes.bodyTypography}
                  >
                   Github , VS Code, React JS , React Native , Firebase , Android Studio , Netbeans
                  </Typography>

                  <Grid item>
                    <Typography variant="body2" sx={classes.buttonTitle}>
                    
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <CodeOffIcon sx={classes.icon} />
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Paper sx={classes.onePaper}>
          <Grid container spacing={4}>
            <Grid item></Grid>
            <Grid item xs={115} sm container>
              <Grid item xs container direction="column" spacing={5}>
                <Grid item xs>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    component="div"
                    sx={classes.titleTypography}
                  >
                       {ui.lang === "en" ? en.INTRODUCE_SUPPORT : ui.lang === "zh" ? zh.INTRODUCE_SUPPORT  : ui.lang === "ar" ? ar.INTRODUCE_SUPPORT : tl.INTRODUCE_SUPPORT }{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={classes.bodyTypography}
                  >
                    Computer Format, Software Installation, Repair
                  </Typography>

                  <Grid item>
                    <Typography variant="body2" sx={classes.buttonTitle}>
                   
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <BuildIcon sx={classes.icon} />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      
      </Box>
     
      <Box sx={classes.twoBox}>
        <Typography sx={classes.introduce}>  {ui.lang === "en" ? en.INTRODUCE : ui.lang === "zh" ? zh.INTRODUCE : ui.lang === "ar" ? ar.INTRODUCE : tl.INTRODUCE}{" "}</Typography>
        <Typography sx={classes.name}>
          {" "}
          {ui.lang === "en" ? en.INTRODUCE_NAME : ui.lang === "zh" ? zh.INTRODUCE_NAME : ui.lang === "ar" ? ar.INTRODUCE_NAME : tl.INTRODUCE_NAME}{" "}
        </Typography>
        <Typography sx={classes.quotes}>
          {" "}
          {ui.lang === "en" ? en.INTRODUCE_QUOTE : ui.lang === "zh" ? zh.INTRODUCE_QUOTE : ui.lang === "ar" ? ar.INTRODUCE_QUOTE : tl.INTRODUCE_QUOTE}{" "}
        </Typography>
        <Typography sx={classes.about}>
          {" "}
          {ui.lang === "en" ? en.INTRODUCE_ABOUT : ui.lang === "zh" ? zh.INTRODUCE_ABOUT : ui.lang === "ar" ? ar.INTRODUCE_ABOUT : tl.INTRODUCE_ABOUT}{" "}
        </Typography>
      </Box>
      <Box sx={classes.boxXs} />
      <Box></Box>
    </Box>
  );
}
