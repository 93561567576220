export const HEADER_ABOUT = "关于"
export const HEADER_WORKS = "作品"
export const HEADER_TESTIMONIALS = "感言"

export const ABOUT_FRONT_END= "前端"
export const ABOUT_DESIGNER = "设计师"
export const ABOUT_WEB_DEVELOPER = "Web开发人员"
export const ABOUT_QUOTE = " 我设计和编码精美简单的东西，我喜欢我所做的事情。"
export const ABOUT_STUDENT = "学生"
export const ABOUT_PROJECT_COMPLETE = "项目完成"

export const INTRODUCE_EDITING_DESIGN = "编辑与设计"
export const INTRODUCE_DEVELOPER = "开发商"
export const INTRODUCE_SUPPORT = "支持"
export const INTRODUCE = "介绍"
export const INTRODUCE_NAME = "你好！我是 Vince Daniel De Leon"
export const INTRODUCE_QUOTE = "每一个伟大的设计都有一个更好的故事"
export const INTRODUCE_ABOUT = "你好！我是 Vince Daniel De Leon 我现在就读于 Bulacan  State University 拥有信息技术或 BSIT 学位. 我在前几个学期的主要成就之一是构建了一个电子商务 Web 和移动应用程序。"

export const WORKS_LATEST = "最新作品"
export const WORKS_QUOTE = "数字体验的完美解决方案"
export const WORKS_NOT_ALLOWED = "某些项目不允许 NDA 发布"
export const WORKS_YOU_WANT = "如果你想看更多,"

export const TESTIMONIALS = "感言"
export const TESTIMONIALS_CLIENT = "客户对我的评价"

export const FOOTER_TOGETHER = "让我们一起创造一些惊人的东西."
export const FOOTER_INFORMATION = "信息"
