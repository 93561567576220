export const HEADER_ABOUT = "About"
export const HEADER_WORKS = "Works"
export const HEADER_TESTIMONIALS = "Testimonials"

export const ABOUT_FRONT_END= "Front End"
export const ABOUT_DESIGNER = "Designer"
export const ABOUT_WEB_DEVELOPER = "Web Developer"
export const ABOUT_QUOTE = "  I design and code beautifully simple things, and I love what I do."
export const ABOUT_STUDENT = "Student"
export const ABOUT_PROJECT_COMPLETE = "Project Completed"

export const INTRODUCE_EDITING_DESIGN = "Editing & Design"
export const INTRODUCE_DEVELOPER = "Developer"
export const INTRODUCE_SUPPORT = "Support"
export const INTRODUCE = "Introduce"
export const INTRODUCE_NAME = "Hello! I'm Vince Daniel De Leon"
export const INTRODUCE_QUOTE = " Every great design with an even better story"
export const INTRODUCE_ABOUT = "Hi Im Vince Daniel De Leon and I currently studying at Bulacan  State University with degree in Information Technology or BSIT.   One of my key accomplisments during previous semesters was were   build an Ecommerce Web and Mobile Application."

export const WORKS_LATEST = "Latest Works"
export const WORKS_QUOTE = "Perfect Solution for digital experience"
export const WORKS_NOT_ALLOWED = "Some project not allowed publish by NDA"
export const WORKS_YOU_WANT = "If you want to see more,"

export const TESTIMONIALS = "Testimonials"
export const TESTIMONIALS_CLIENT = "What's clients say about me"

export const FOOTER_TOGETHER = "Let's make something amazing together."
export const FOOTER_INFORMATION = "Information"
