export const HEADER_ABOUT = "Tungkol sakin"
export const HEADER_WORKS = "Gawa"
export const HEADER_TESTIMONIALS = "Pagpapatunay"

export const ABOUT_FRONT_END= "Harap na gawain"
export const ABOUT_DESIGNER = "Pag disenyo"
export const ABOUT_WEB_DEVELOPER = "Pag gawa ng pahinarya"
export const ABOUT_QUOTE = "Ako ay nag didisenyo at pinapadali ang pag code at gusto ko ang ginagawa ko."
export const ABOUT_STUDENT = "Studyante"
export const ABOUT_PROJECT_COMPLETE = "Natapos na Proyekto" 

export const INTRODUCE_EDITING_DESIGN = "Pag-edit at Disenyo"
export const INTRODUCE_DEVELOPER = "Developer"
export const INTRODUCE_SUPPORT = "Suporta"
export const INTRODUCE = "Introduksyon"
export const INTRODUCE_NAME = "Magandang araw! Ako si Vince Daniel De Leon"
export const INTRODUCE_QUOTE = "Ang bawat magandang gawa ay may magandang storya"
export const INTRODUCE_ABOUT = "Ako si Vince Daniel at kasalukuyang nag aaral sa paaralang ng Bulacan State University sa kursong Impormasyong Pang teknolohiya or BSIT. Isang sa malaking kong nakamit ay ang nakagawa ng isang Ecommerce Web and Mobile Application nakaraang semester."

export const WORKS_LATEST = "Pinakabagong Gawa"
export const WORKS_QUOTE = "Magandang solusyon sa makabagong karanasan"
export const WORKS_NOT_ALLOWED = "* Ang ibang proyekto ay hindi pinaoayagan ng NDA"
export const WORKS_YOU_WANT = "Kung gusto mo pa makita ang iba,"

export const TESTIMONIALS = "Pagpapatunay"
export const TESTIMONIALS_CLIENT = "Mga komento sa akin ng klayente"

export const FOOTER_TOGETHER = "Magkasama tayong gumawa ng isang bagay na kamangha-mangha."
export const FOOTER_INFORMATION = "Impormasyon"