import React from "react";
import { Box } from "@mui/system";
import { Typography, Modal } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Email from '../components/Email';

import { useSelector } from "react-redux";
import * as tl from "../lang/tl";
import * as en from "../lang/en";
import * as zh from "../lang/zh";
import * as ar from "../lang/ar";

const classes = {
  mainBox: {
    display: {
      xs: "block",
      sm: "flex",
      md: "flex",
      xl: "flex",
    },
    flexDirection: "row",
    alignItems: "center",
    textAlign: "left",
    backgroundColor: (theme) => theme.palette.primary.main,
    mt:5,
  },
  oneBox: {
    width: "50%",

    mx: {
      xs: 15,
      sm: 5,
      md: 10,
      lg: 25,
    },
   p:2,
    mt:2,
    display: {
      xs: "none",
      sm: "flex",
     
    },
    flexDirection: "column",
    alignItems: "right",
  },
  twoBox: {
    width: "auto",
    mx: {
      xs: 15,
      sm:15,
      md:25,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "right",
    p:2,
  },
  threeBox: {
    width: "auto",
    flexDirection: "row",
    alignItems: "right",
    mt: 1,
    display: {
      xs: "none",
      sm: "flex",
     
    },
  },
  fourBox: {
    width: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "right",
    mt: {
      xs: 5,
      sm: 7,
     
    },
    ml: 1,
  },

  titleTypography: {
    color: "white",
    fontSize: {
     
      sm: 20,
      md: 25,
      lg: 30,
    },
    
  },
  bodyTypography: {
    mt: 5,
    color: "white",
    fontSize: {
      xs: 20,
      sm: 15,
      md: 25,
      lg: 30,
    },
  },
  informationTypography: {
    ml: 1,
    color: "white",
    fontSize: {
      xs: 10,
      sm: 10,
      md: 11,
      lg: 12,
    },
    display:{
      xs:"none",
      sm:"flex"
    },
    mt:2,
  },
  addressTypography: {
    ml: 1,
    color: "white",
    mt: 4,
    fontSize: {
      xs: 15,
      sm: 14,
      md: 17,
      lg: 20,
    },
  },
  emailTypography: {
    ml: 1,
    color: "white",
    mt: 2,
    fontSize: {
      xs: 12,
      sm: 14,
      md: 17,
      lg: 20,
    },
  },
  nameTypography: {
    mt: 5,
    mr: 4,
    color: "white",
    fontSize: 17,
    fontWeight: 700,
  },
  rightTypography: {
    mt: 5.5,
    color: "white",
    fontSize: 12,
    ml: 1,
    display:{
      xs:"none",
      md: "flex",
    }
  },
  rightTypography1: {
    mt: 5.5,
    color: "white",
    fontSize: 9,
    display:{
      xs:"flex",
      md: "none",
    },
    flexDirection: "column",
    alignItems: "center",
    ml:-19,
  },
  icon: {
    fontSize: 25,
    mr: 2,
    color: "white",
    "&:hover": {
      color: "black",
    },
  },
  logoText: {
    color: "white",
    fontSize: 28,
    fontFamily: " Arial",
    mt: 3,
  },
  logoText1: {
    mt: 3,
    color: "white",
    fontSize: 32,
    fontWeight: 800,
    fontFamily: " Arial",
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "gray",
  borderRadius:2,
  boxShadow: 24,
  p: 4,
};

export default function Footer() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const ui = useSelector((state) => state.ui);

  return (
    <Box sx={classes.mainBox}>
      <Box sx={classes.oneBox}>
        <Typography sx={classes.titleTypography}>
        {ui.lang === "en" ? en.FOOTER_TOGETHER : ui.lang === "zh" ? zh.FOOTER_TOGETHER : ui.lang === "ar" ? ar.FOOTER_TOGETHER : tl.FOOTER_TOGETHER}
        </Typography>
        <Typography sx={classes.bodyTypography}></Typography>

   
        
          <Typography sx={classes.rightTypography}>
            © 2022. All Rights Reserved
          </Typography>
 
      </Box>
      <Box sx={classes.twoBox}>
        <Typography sx={classes.informationTypography}> {ui.lang === "en" ? en.FOOTER_INFORMATION : ui.lang === "zh" ? zh.FOOTER_INFORMATION :  ui.lang === "ar" ? ar.FOOTER_INFORMATION : tl.FOOTER_INFORMATION}</Typography>
        <Typography sx={classes.addressTypography}>
          Bulacan, Philippines.
        </Typography>
        <Typography sx={classes.emailTypography}>
          vincedaniel20@gmail.com
        </Typography>

        <Box sx={classes.fourBox}>
          <GitHubIcon
            sx={classes.icon}
            onClick={() =>
              (window.location.href = "https://github.com/vincedaniel08")
            }
          />
          <FacebookIcon
            sx={classes.icon}
            onClick={() =>
              (window.location.href = "https://facebook.com/vincedaniel08")
            }
          />
          <InstagramIcon
            sx={classes.icon}
            onClick={() =>
              (window.location.href = "https://www.instagram.com/garabitbit")
            }
          />
          <MailOutlineIcon sx={classes.icon} onClick={handleOpen} />

          <Typography sx={classes.rightTypography1}>
            © 2022. All Rights Reserved
          </Typography>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography variant="h6" component="h2" sx={{color: "white"}}>
                Contact
              </Typography>
              <Email />
            </Box>
          </Modal>
        </Box>
      </Box>
    </Box>
  );
}
