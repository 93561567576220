
const theme = (isDarkMode) => ({

    palette: {
        mode: isDarkMode ? 'light' : "dark",
        primary: {
          main: "#5DDC9A",
        },
        secondary: {
          main: '#23A361',
        },
      },

      typography:{
        fontFamily: "monospace",
        fontSize: 12,

      },
});

export default theme;