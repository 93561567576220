import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
const classes ={
    mainBox:{
        display:"flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "left",
        color: "white",
        mx:10,
        
    },
}
export default function Email  ()  {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_llj999r', 'template_8jcd12k', form.current, 'user_pUbJLadImLvwIkkXJUp5J')
      .then((result) => {
          console.log(result.text);
          alert("Successfully send");
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
      
    <form ref={form} onSubmit={sendEmail}>
        <Box sx={classes.mainBox}>
        <TextField id="outlined-basic" label="Name" variant="outlined"  type="text" name="user_name" sx={{my:2 }}/>
        <TextField id="outlined-basic" label="Email" variant="outlined"  type="email" name="user_email" sx={{my:2}}/>
        <TextField id="outlined-basic" label="Message" variant="outlined"  type="text"name="message" sx={{my:2}}/>
      <input type="submit" value="Send" />
      </Box>
    </form>
   
  );
};