import React from "react";
import { Box } from "@mui/system";
import { Typography, Paper, Link } from "@mui/material";

import techware from "./images/techware.png";
import agrishop from "./images/agrishop.png";
import prototype from "./images/agriPrototype.png";

import { useSelector } from "react-redux";
import * as tl from "../lang/tl";
import * as en from "../lang/en";
import * as zh from "../lang/zh";
import * as ar from "../lang/ar";

import AOS from "aos";
import "aos/dist/aos.css"; 
AOS.init();

const classes = {
  mainBox: {
    display: {
      xs: "block",
      sm: "block",
      md: "block",
      lg: "flex",
      xl: "flex",
    },
    flexDirection: "row",
    alignItems: "center",
    textAlign: "left",
    backgroundColor: (theme) => theme.palette.background.default,
  },
  oneBox: {
    mt: 10,
    mb: {
      xs: 0,
      lg: 10,
    },
    width: "auto",
    mx: {
      xs: 1,
      sm: 5,
      md: 4,
      xl: 10,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  threeBox: {
    mb: 10,
    width: "auto",
    mx: {
      xs: 1,
      sm: 5,
      md: 10,
      xl: 12,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  onePaper: {
    margin: "auto",
    maxWidth: "auto",
    flexGrow: 1,
    backgroundColor: "#2C2D31",
    mt: 2,
    borderRadius: 2,
    mb: 10,
    "&:hover": {
      backgroundColor: (theme) => theme.palette.primary.main,
    },
  },

  twoPaper: {
    "&:hover": {
      backgroundColor: (theme) => theme.palette.primary.main,
    },
    margin: "auto",
    maxWidth: "auto",
    flexGrow: 1,
    backgroundColor: "#1e1e1e",
    mt: 2,
    borderRadius: 2,
  },
  threePaper: {
    "&:hover": {
      backgroundColor: (theme) => theme.palette.primary.main,
    },
    margin: "auto",
    maxWidth: "auto",
    flexGrow: 1,
    backgroundColor: "#1e1e1e",
    mt: 2,
    borderRadius: 2,
  },

  fourPaper: {
    margin: "auto",
    maxWidth: "auto",
    flexGrow: 1,
    backgroundColor: (theme) => theme.palette.background.default,
    mt: 2,
    borderRadius: 2,
    display: {
      xs: "none ",
      sm: "none",
      md: "none",
      lg: "block",
      xl: "block",
    },
  },
  fivePaper: {
    margin: "auto",
    mb: 5,
    maxWidth: "auto",
    flexGrow: 1,
    backgroundColor: (theme) => theme.palette.background.default,
    mt: 2,
    borderRadius: 2,
    display: {
      lg: "none",
      xl: "none",
    },
  },
  twoBox: {
    p: 2,
    mx: 2,
    mt: 2,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: {
      xs: 250,
      sm: 270,
      md: 300,
      xl: 400,
    },
  },

  oneBoxPic: {
    mt: 4,
    backgroundImage: `url(${techware})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    width: {
      xs: 270,
      sm: 350,
      md: 450,
      lg: 500,
      xl: 450,
    },
    height: {
      xs: 250,
      sm: 350,
      md: 450,
      lg: 500,
      xl: 550,
    },
    borderRadius: 2,
  },
  twoBoxPic: {
    mt: 2,
    backgroundImage: `url(${agrishop})`,
    backgroundSize: "cover",
    backgroundPosition: "right right",
    backgroundRepeat: "no-repeat",
    width: {
      xs: 320,
      sm: 350,
      md: 450,
      lg: 500,
      xl: 450,
    },
    height: {
      xs: 250,
      sm: 350,
      md: 450,
      lg: 500,
      xl: 550,
    },
    borderRadius: 2,
  },
  threeBoxPic: {
    mt: 2,
    backgroundImage: `url(${prototype})`,
    backgroundSize: "cover",
    backgroundPosition: "right right",
    backgroundRepeat: "no-repeat",
    width: {
      xs: 300,
      sm: 350,
      md: 450,
      lg: 500,
      xl: 450,
    },
    height: {
      xs: 250,
      sm: 350,
      md: 450,
      lg: 500,
      xl: 550,
    },
    borderRadius: 2,
  },
  titleTypography: {
    fontSize: {xs:30,md:40},
    mt: 5,
  },

  titlePaper: {
    color: "white",
    fontSize: {
      xs: 15,
      sm: 20,
      md: 23,
      lg: 27,
      xl: 30,
    },
  },
  bodyTypography: {
    fontSize: 12,
  },
  boxTypography: {
    fontSize: 12,
    color: "white",
  },
  button: {
    mr: 2,
    p: 1,
    ml: {
      xs: 15,
      sm: 20,
      md: 25,
      lg: 26,
      xl: 27,
    },
    borderRadius: 1,
    backgroundColor: (theme) => theme.palette.primary.main,
    color: "black",
    textTransform: "none",
    display: "flex",
    boxShadow: 5,
  },
  buttonLabel: {
    fontSize: 9,
  },
  link: {
    fontSize: 30,
    color: "#50DC9A",
  },
  linkOne: {
    fontSize: 15,
    color: "#50DC9A",
  },
  linkTypography: {
    fontSize: 15,
    color: "gray",
    mb: 1,
    mt: 5,
  },
  linkTwoTypography: {
    fontSize: 15,
    color: "gray",
    mb: 1,
  },
  boxXs: {
    display: {
      xs: "flex",
      sm: "flex",
      md: "none",
      xl: "none",
    },
    width: "100%",
  },
};

export default function LatestWorks() {
  const ui = useSelector((state) => state.ui);
  return (
    <Box sx={classes.mainBox}>
      <Box sx={classes.threeBox}>
        <Typography sx={classes.titleTypography} color="textPrimary">
          {" "}
          {ui.lang === "en" ? en.WORKS_LATEST : ui.lang === "zh" ? zh.WORKS_LATEST : ui.lang === "ar" ? ar.WORKS_LATEST : tl.WORKS_LATEST}
        </Typography>
        <Typography sx={classes.bodyTypography} color="textPrimary">
        {ui.lang === "en" ? en.WORKS_QUOTE : ui.lang === "zh" ? zh.WORKS_QUOTE : ui.lang === "ar" ? ar.WORKS_QUOTE : tl.WORKS_QUOTE}
        </Typography>

        <Box sx={classes.oneBox}>
          <Paper data-aos="zoom-in-right"
            sx={classes.threePaper}
            onClick={() =>
              (window.location.href =
                "https://www.figma.com/file/D2J2eBrwYM8Zxqx8awvre7/Agrishop-Web-Prototype?node-id=0%3A1")
            }
          >
            <Box sx={classes.twoBox}>
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                sx={classes.titlePaper}
              >
                Prototype
              </Typography>

              <Box sx={classes.button}>
                <Typography sx={classes.buttonLabel}>Figma</Typography>
              </Box>
            </Box>

            <Box sx={classes.threeBoxPic} />
          </Paper>
        </Box>

        <Box sx={classes.fourPaper}>
          <Typography sx={classes.linkTypography}></Typography>
          <Typography sx={classes.linkTypography}>
          {ui.lang === "en" ? en.WORKS_NOT_ALLOWED  : ui.lang === "zh" ? zh.WORKS_NOT_ALLOWED : ui.lang === "ar" ? ar.WORKS_NOT_ALLOWED : tl.WORKS_NOT_ALLOWED}
          </Typography>
          <Typography sx={classes.linkTwoTypography}>
          {ui.lang === "en" ? en.WORKS_YOU_WANT : ui.lang === "zh" ? zh.WORKS_YOU_WANT :  ui.lang === "ar" ? ar.WORKS_YOU_WANT : tl.WORKS_YOU_WANT}{" "}
            <Link href="#" color="#ffff" sx={classes.linkOne}>
              contact
            </Link>
          </Typography>
        </Box>
      </Box>

      <Box sx={classes.oneBox} >
        <Paper data-aos="zoom-in-left"
          sx={classes.onePaper}
          onClick={() =>
            (window.location.href =
              "https://github.com/vincedaniel08/TechwareMobileApp")
          }
        >
          <Box sx={classes.twoBox}>
            <Typography
              gutterBottom
              variant="subtitle1"
              component="div"
              sx={classes.titlePaper}
            >
              Techware
            </Typography>

            <Box sx={classes.button}>
              <Typography sx={classes.buttonLabel}>Android Studio</Typography>
            </Box>
          </Box>

          <Box sx={classes.oneBoxPic} />
        </Paper>

        <Paper  data-aos="zoom-in-down"
          sx={classes.twoPaper}
          onClick={() =>
            (window.location.href =
              "https://github.com/vincedaniel08/AgriShopEcommerce")
          }
        >
          <Box sx={classes.twoBox}>
            <Typography
              gutterBottom
              variant="subtitle1"
              component="div"
              sx={classes.titlePaper}
            >
              Agrishop
            </Typography>

            <Box sx={classes.button}>
              <Typography sx={classes.buttonLabel}>React</Typography>
            </Box>
          </Box>

          <Box sx={classes.twoBoxPic}></Box>
        </Paper>

        <Box sx={classes.fivePaper}>
          {/** <Link href="#" color="#ffff" sx={classes.link} >
            All project
          </Link>  */}
          <Typography sx={classes.linkTypography}></Typography>
          <Typography sx={classes.linkTypography}>
          {ui.lang === "en" ? en.WORKS_NOT_ALLOWED  : ui.lang === "zh" ? zh.WORKS_NOT_ALLOWED : ui.lang === "ar" ? ar.WORKS_NOT_ALLOWED : tl.WORKS_NOT_ALLOWED}
          </Typography>
          <Typography sx={classes.linkTwoTypography}>
          {ui.lang === "en" ? en.WORKS_YOU_WANT : ui.lang === "zh" ? zh.WORKS_YOU_WANT :  ui.lang === "ar" ? ar.WORKS_YOU_WANT : tl.WORKS_YOU_WANT}{" "}
            <Link href="#" color="#ffff" sx={classes.linkOne}>
              contact
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
