export const HEADER_ABOUT = "حول"
export const HEADER_WORKS = "يعمل"
export const HEADER_TESTIMONIALS = "شهادة"

export const ABOUT_FRONT_END= "نهاية المقدمة"
export const ABOUT_DESIGNER = "مصمم"
export const ABOUT_WEB_DEVELOPER = "مطور ويب"
export const ABOUT_QUOTE = "  أنا أصمم وأبرمج أشياء بسيطة بشكل جميل ، وأنا أحب ما أفعله."
export const ABOUT_STUDENT = "طالب علم"
export const ABOUT_PROJECT_COMPLETE = "اكتمل المشروع"

export const INTRODUCE_EDITING_DESIGN = "التحرير والتصميم"
export const INTRODUCE_DEVELOPER = "مطور"
export const INTRODUCE_SUPPORT = "الدعم"
export const INTRODUCE = "تقديم"
export const INTRODUCE_NAME = "أهلا! انا Vince Daniel De Leon"
export const INTRODUCE_QUOTE = " كل تصميم رائع بقصة أفضل"
export const INTRODUCE_ABOUT = "مرحبًا Vince Daniel De Leon  وأنا أدرس حاليًا في Bulacan State University  بدرجة في تكنولوجيا المعلومات أو BSIT. كان أحد إنجازاتي الرئيسية خلال الفصول الدراسية السابقة هو بناء تطبيق ويب للتجارة الإلكترونية والجوّال."

export const WORKS_LATEST = "أحدث الأعمال"
export const WORKS_QUOTE = "الحل الأمثل للتجربة الرقمية"
export const WORKS_NOT_ALLOWED = "بعض المشاريع لا يسمح بنشرها من قبل NDA"
export const WORKS_YOU_WANT = "إذا كنت تريد رؤية المزيد ،"

export const TESTIMONIALS = "الشهادات - التوصيات"
export const TESTIMONIALS_CLIENT = "ماذا يقول العملاء عني"

export const FOOTER_TOGETHER = "دعونا نصنع شيئًا رائعًا معًا."
export const FOOTER_INFORMATION = "معلومة"
