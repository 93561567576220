import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch , Route} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import theme from '../utils/theme';
import Index from "../pages/index";
import { useSelector } from "react-redux";

import Loading from "../components/Loading" ;

export default function Routes() {
  const [state, setstate] = useState({
    isLoading: true,
  });

  useEffect(() => {
   
    const timer = setTimeout(() => {
      setstate({isLoading: false})
    }, 1000);

    return () => clearTimeout(timer);

  }, []);


  const ui = useSelector(state => state.ui)
const THEME = createTheme(theme(ui.isDarkMode));

  if (state.isLoading) {
  return <Loading />;
}
  return (
   <ThemeProvider theme={THEME}>
      <Router>
        <Switch>
           <Route component={Index} path="/"/>
        </Switch>
      </Router>
      </ThemeProvider>

  );
}
